/* colors */
:root {
  --primary: #0000ff;
  --positive: #4caf50;
  --negative: #f44336;
  --warning: #ff9800;
  --white: #ffffff;
  --black: #000000;
  --grey: #aaaaaa;
  --grey-light: #f0f0f0;
  --grey-dark: #666666;
}
