.common-layout {
  font-family: 'Lato', sans-serif;
  margin: 0;
}

.common-layout > main {
  margin: 0;
}

.header {
  display: grid;
  grid-template-columns: 0.1fr 2fr;
  align-items: center;
  border-bottom: 1px solid #dddddd;
}

.header-button {
  display: grid;
  justify-items: center;
  cursor: pointer;
}

.menu {
  width: 16px;
  height: 2px;
  margin: 1.5px 0;
}

.header-text {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  color: black;
}
