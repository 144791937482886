/* root */
.button {
  width: 235px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  font-size: 1rem;
  font-weight: 700;
  color: #ffffff;
  border-radius: 50px;
  background-color: #4340C0;
  border: 1px solid var(--grey);
}

/* states */
.button:hover {
  border: 1px solid var(--white);
}

.button img {
  height: 1.2rem;
  width: 1.2rem;
  margin-right: 0.5rem;
}
