
.pages-authorize {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr .1fr;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%); 
}

.pages-authorize > p {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
}
.pages-authorize > p > img {
  margin-right: 0.5rem;
}
