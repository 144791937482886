h1 {
    display: table;
    margin: 40px auto;
    color: rgb(180, 84, 6);
    font: 30px Helvetica;
    letter-spacing: 2px;
    text-transform: uppercase;
    letter-spacing: 10px;
  }

  h2 {
    display: table;
    margin: 40px auto;
    color: rgb(235, 142, 33);
    font: 30px Helvetica;
    letter-spacing: 1px;
    text-transform: uppercase;
    letter-spacing: 10px;
  }


  h3 {
    display: table;
    margin: 40px auto;
    font: 20px Helvetica;
  }
  
  form {
    display: table;
    margin: 40px auto;
  
    input,
    textarea {
      font: 18px Helvetica, Arial, sans-serif;
      box-sizing: border-box;
      display: block;
      border: none;
      padding: 20px;
      width: 300px;
      margin-bottom: 20px;
      font-size: 18px;
      outline: none;
      transition: all 0.2s ease-in-out;
  
      &::placeholder {
        transition: all 0.2s ease-in-out;
        color: #999;
        font: 18px Helvetica, Arial, sans-serif;
      }
  
      &:focus,
      &.populated {
        padding-top: 28px;
        padding-bottom: 12px;
  
        &::placeholder {
          color: transparent;
        }
      }
    }
  
    textarea {
      height: 200px;
    }
  
    button {
      transition: all 0.2s ease-in-out;
      font: 18px Helvetica, Arial, sans-serif;
      border: none;
      background: #0B315E;
      border-radius: 100px;
      color: #fff;
      padding: 20px;
      width: 100%;
      text-transform: uppercase;
      letter-spacing: 5px;
  
      &:hover {
        background: #14519c;
        cursor: pointer;
      }
    }
  }
  