.common-header {
  font-family: 'Lato', sans-serif;
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}
.common-header > .brand {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2rem;
}

.common-header > .brand > h2 {
  font-family: 'Lato', sans-serif;
  padding-left: 20px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
}

.common-header > .menu {
  display: flex;
  flex-direction: row;
  padding: 0 1rem;
  padding-left: 2rem;
  background-color: #8b9096;
  border-radius: 15px;
  width: auto;
}

.MuiBottomNavigationAction-root.Mui-selected {
  /* Increase the specificity */
  color: #f08421 !important;
  background-color: #f1f1f1;
}

.grid-container {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  text-align: center;
}

.grid-container-footer {
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  text-align: center;
  padding: 10px;
}

.grid-item {
  max-width: 730px;
  margin: 20px;
}
